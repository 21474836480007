<template>
    <div id="root">
        <div class="fixed-background"></div>
        <main>
            <div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<nav class="navbar fixed-top bg-warning">
							<img src="/frontend/assets/img/logo.png" class="logo-img mx-auto">
						</nav>
					</div>
				</div>
				<div class="row" style="margin-top: 100px;">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title">{{ $t('register.title') }}</h5>
								<p>{{message}}</p>
							</div>
						</div>
					</div>
				</div>
            </div>
        </main>
    </div>
</template>
<script>
export default {
data () {
		return {
			message: '',
		}
	},
	methods: {
		//this.get token
		/*checkToken() {
			this.$http.get(this.url + '/User/register/' + this.$route.params.token {
			}).then(function(response) {
				if (response.status && response.data.status) {
					this.$message this.$t('messages.user.register.success'); 
				} else {
					this.$message = this.$t('messages.user.register.failed'); 
				}
			});	
		},*/
	},
	mounted () {
		this.checkToken();
	}
}
</script>
